import { AuthService } from '@mca/auth/domain';
import { HolidaysService } from '@mca/references/api';
import { PaymentFrequency } from '@mca/shared/domain';
import { dateAsYYYYMMDD } from '@mca/shared/util';
import {
  BehaviorSubject,
  EMPTY,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { MCAProgramTypes } from '../../../entities/mca-consts';
import { McaOfferParams, McaScheduleState, McaScheduleStatePartial, ScheduleOfferForm } from '../../../entities/mca-schedule';
import { McaOffer, McaOfferData, OfferCalcData, OfferCalcParams } from '../../../entities/offer';
import { ExposureRec, ScheduleResponse, ScheduleResponseParams, calcExposureTotals } from '../../../entities/offer-mca-rec';
import { McaService } from '../../../infrastructure/mca.service';
import { McaPageService } from '../../mca-page.service';
import { IsoUserMapService } from '../iso-user-map.service';
import { FUNDING_ROUDING, ScheduleFunctions } from './schedule-functions';
import { MessageService } from 'primeng/api';
import { signal } from '@angular/core';

const resetUserFees = {
  iso_contract_fee_pct: 0,
  isorep_contract_fee_pct: 0,
  ins_commission_contract_fee_pct: 0,
  commission_contract_fee_pct: 0,
  cf_contract_fee_pct: 0,
  iso_contract_fee_bonus_pct: 0,
  isorep_contract_fee_bonus_pct: 0,
  commission_contract_fee_bonus_pct: 0,
  ins_commission_contract_fee_bonus_pct: 0,
};

export class McaSchedule {
  private offerFormSubject = new BehaviorSubject<ScheduleOfferForm>({
    program: MCAProgramTypes.deal,
    fundedAmt: 0,
    fee: 0,
    fixed_cf: 0,
    rate: 1.5,
    days: 1,
    fixPayment: false,
    withdrAmt: 0,
    discountFactorRate: 0,
    daysDisc: 1,
    selectedDepFreq: PaymentFrequency.weekly,
    selectedWithdFreq: PaymentFrequency.daily,
    depStartDate: new Date(),
    withdrStartDate: this.holidaysService.addBusinessDays(new Date(), 1),
    numberOfIncrements: 3,
    incrementFrequency: 20,
    sameIncrementAmount: false,
    templateId: null,
    switch_to_daily_payments: false,
  });

  private offerParamsSubject = new BehaviorSubject<McaOfferParams>({
    // derived values
    feeAmt: 0,
    expectedRet: 0,
    additionalAmt: 0,
    netToMerchantAmt: 0,
    expAsFundAmt: 0,
    savingsPct: 0,
    expectedRetDisc: 0,
    trueRate: 0,
    trueRateDisc: 0,
    buyOutAmt: 0,
    sameIncrementAmount: false,

    // intermediate values
    totPayment: 0,
    totCurrentBalance: 0,

    exposureRec: new ExposureRec(),
    commissions: {
      iso_commission_rtr_pct: 0,
      iso_contract_fee_pct: 0,
      isorep_commission_pct: 0,
      isorep_contract_fee_pct: 0,
      ins_commission_rtr_pct: 0,
      ins_commission_contract_fee_pct: 0,
      commission_comm_rtr_pct: 0,
      commission_contract_fee_pct: 0,
      cf_commission_rtr_pct: 0,
      cf_contract_fee_pct: 0,
      iso_commission_rtr_bonus_pct: 0,
      iso_contract_fee_bonus_pct: 0,
      isorep_commission_bonus_pct: 0,
      isorep_contract_fee_bonus_pct: 0,
      commission_comm_rtr_bonus_pct: 0,
      commission_contract_fee_bonus_pct: 0,
      ins_commission_rtr_bonus_pct: 0,
      ins_commission_contract_fee_bonus_pct: 0,
      cf_commission_rtr_bonus_pct: 0,
      cf_contract_fee_bonus_pct: 0,
    },
    deposits: [],
    withdrawals: [],
    programIncrements: [],
  });

  calcInProgress = signal(false);

  get offerForm() {
    return this.offerFormSubject.value;
  }

  get offerParams() {
    return this.offerParamsSubject.value;
  }

  get offerForm$() {
    return this.offerFormSubject.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
  }

  get offerParams$() {
    return this.offerParamsSubject.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
  }

  get mca() {
    return this.mcaPageService.get('mca');
  }

  get minLastWithdrawal() {
    return +(this.authService.systemConfig?.minLastPaymentAmount ?? 0);
  }

  get state(): McaScheduleState {
    return { offerForm: { ...this.offerForm }, ...this.offerParams };
  }
  state$ = combineLatest([this.offerForm$, this.offerParams$]).pipe(
    map(([offerForm, offerParams]) => ({
      offerForm,
      ...offerParams,
    })),
    shareReplay(1),
  );

  get stateUpdate$() {
    return this.state$.pipe(debounceTime(0), first());
  }

  constructor(
    private holidaysService: HolidaysService,
    private mcaPageService: McaPageService,
    private mcaService: McaService,
    private isoUserMapService: IsoUserMapService,
    private authService: AuthService,
    private messageService: MessageService,
  ) {
    this.initData();
  }

  updateOfferForm(offerForm: Partial<ScheduleOfferForm>) {
    this.offerFormSubject.next({ ...this.offerForm, ...offerForm });
  }

  updateState(state: McaScheduleStatePartial) {
    const { offerForm, ...offerParams } = state;
    if (offerForm) {
      this.updateOfferForm(offerForm);
    }
    if (Object.keys(offerParams).length) {
      this.offerParamsSubject.next({ ...this.offerParams, ...offerParams });
    }
  }

  buyOutAmt() {
    return this.mca?.outstandingLoans.filter(loan => loan.buyout).reduce((acc, loan) => acc + loan.balanceRemains, 0);
  }

  validate() {
    const messages = [];
    if (!this.offerForm.fundedAmt) {
      messages.push('"Funding Amt" is required');
    }
    if (!this.offerForm.rate) {
      messages.push('"C Rate" is required');
    }
    if (!this.offerForm.withdrAmt) {
      messages.push('"Payment$" is required');
    }
    if (!this.offerForm.depStartDate) {
      messages.push('"Dep Start Date" is required');
    }
    if (!this.offerForm.withdrStartDate) {
      messages.push('"Withdr Start Date" is required');
    }
    if (!this.offerForm.days) {
      messages.push('"# days" is required');
    }
    if (this.offerParams.additionalAmt < 0) {
      messages.push(
        'This offer contains a negative additional capital in the ADDITIONAL$.  Recreate a new offer where additional capital is not less than $0.00',
      );
    }
    return messages;
  }

  applyOffer(offer: McaOffer) {
    const loadedState = ScheduleFunctions.offerDataToScheduleState(this.state, offer);
    // regenerate schedule and merge it with data from loaded offer
    const loadedDeposits = McaOffer.expandPayments(offer.deposit);
    const outstandingTotals = ScheduleFunctions.calcOutstandingTotals(offer.funding_type, this.mca);
    const resultState = {
      ...this.state,
      ...loadedState,
      offerForm: {
        ...this.state.offerForm,
        ...loadedState.offerForm,
        incrementFrequency: offer.deposit_freq,
        numberOfIncrements: loadedDeposits.length,
      },
      ...outstandingTotals,
      offerId: offer.id,
    } as McaScheduleState;
    if (offer.funding_type === MCAProgramTypes.incrementalDeal) {
      resultState.offerForm.selectedDepFreq = PaymentFrequency.weekly;
    }
    this.updateState(resultState);
    // wait for calc of expectedRet - it's required for withdrawals calc
    this.stateUpdate$.subscribe(() => {
      this.mca.num_payments = this.state.withdrawals.length;
    });
  }

  isConsolidation() {
    return (
      this.offerForm.program === MCAProgramTypes.consolidation ||
      (this.offerForm.program === MCAProgramTypes.incrementalDeal && this.getTemplate()?.use_consolidation_increments)
    );
  }

  calcSuggested() {
    const suggested = ScheduleFunctions.calcSuggested(this.state, this.mca);
    const dbaFixedCF = this.authService.getFixedFee(this.mca.dbaRec, suggested.offerForm.fundedAmt);
    this.updateState(ScheduleFunctions.applyDbaFixedFee(suggested, dbaFixedCF));
  }

  getOfferData$(setIndex: number) {
    const commUsers = this.mca.mcaCommisionUsers.filter(user => user.setindex === setIndex);
    return this.isoUserMapService.getUserSetCommissionsFromOfferCommissions$(this.offerParams.commissions, commUsers).pipe(
      map(users => {
        const offer = new McaOffer();
        offer.setMcaData(this.mca);
        offer.setCommSetData({ users });
        const { deposits, withdrawals } = this.state;
        offer.setScheduleData({ state: this.state, deposits, withdrawals });
        if (this.offerForm.program === MCAProgramTypes.incrementalDeal) {
          offer.deposit_freq = this.offerForm.incrementFrequency;
        }
        offer.id = 0;
        return offer;
      }),
    );
  }

  calc$(calcParams?: { numberOfDeposits?: number; fixed_cf?: number }) {
    if (this.offerForm.fundedAmt < FUNDING_ROUDING || (this.offerForm.fixPayment && !this.offerForm.withdrAmt)) {
      console.warn('Nothing to calc');
      return EMPTY;
    }
    this.calcInProgress.set(true);
    const setIndex = this.mcaPageService.get('loadedOffers')?.[0]?.commissionset ?? 1;
    return this.getOfferData$(setIndex).pipe(
      switchMap(offer => this.prepareCalcRequest$({ ...offer }, calcParams)),
      catchError(() => EMPTY),
      finalize(() => this.calcInProgress.set(false)),
      tap(response => this.applyCalcResponse(response)),
    );
  }

  resetUserFees() {
    this.updateState({ commissions: { ...this.offerParams.commissions, ...resetUserFees } });
  }

  private prepareCalcRequest$(offer: McaOfferData, calcParams?: { numberOfDeposits?: number; fixed_cf?: number }) {
    const scheduleCommissions = this.offerParams.commissions;
    const params = {
      numberOfDeposits: calcParams?.numberOfDeposits || this.offerForm.numberOfIncrements,
      commissions: {
        iso_commission_rtr_pct: scheduleCommissions.iso_commission_rtr_pct,
        isorep_commission_pct: scheduleCommissions.isorep_commission_pct,
        ins_commission_rtr_pct: scheduleCommissions.ins_commission_rtr_pct,
        commission_comm_rtr_pct: scheduleCommissions.commission_comm_rtr_pct,
        cf_commission_rtr_pct: scheduleCommissions.cf_commission_rtr_pct,
        iso_contract_fee_pct: scheduleCommissions.iso_contract_fee_pct,
        isorep_contract_fee_pct: scheduleCommissions.isorep_contract_fee_pct,
        ins_commission_contract_fee_pct: scheduleCommissions.ins_commission_contract_fee_pct,
        commission_contract_fee_pct: scheduleCommissions.commission_contract_fee_pct,
        cf_contract_fee_pct: scheduleCommissions.cf_contract_fee_pct,
      },
    } as OfferCalcParams;
    if (this.mca.depStartDate && this.mcaService.statusIsOfferAccepted(this.mca.position.status)) {
      params.withdrawal_start_date = dateAsYYYYMMDD(this.mca.paybackStartDate);
      params.deposit_start_date = dateAsYYYYMMDD(this.mca.depStartDate);
    }
    if (!this.offerForm.fixPayment) {
      params.days = this.offerForm.days;
      params.daysDisc = this.offerForm.daysDisc;
    }
    if (this.offerParams.programIncrements.length) {
      params.programIncrements = this.offerParams.programIncrements;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { commissions, fixed_cf, ...offerData } = offer;
    if (offer.id && offer.fixed_cf) {
      (offerData as OfferCalcData).fixed_cf = offer.fixed_cf;
    }
    const fixedFeeSteps = this.authService.getFixedFeeSteps(this.mcaPageService.get('mca').dbaRec);
    if (fixedFeeSteps) {
      params.fixedFeeSteps = JSON.stringify(fixedFeeSteps);
    }
    if (calcParams?.fixed_cf !== undefined || (!fixedFeeSteps && this.offerForm.fixed_cf)) {
      (offerData as OfferCalcData).fixed_cf = fixed_cf || this.offerForm.fixed_cf;
    }
    return this.mcaService.calcOfferSchedule(offerData, params);
  }

  applyCalcResponse(response: ScheduleResponse | ScheduleResponseParams) {
    const exposureRec = new ExposureRec();
    exposureRec.schedule = response.exposure;
    calcExposureTotals(exposureRec);
    const resultExposure = { exposureRec, exposure: exposureRec.totExposure, expAsFundAmt: response.expAsFundAmt };
    const updates: McaScheduleStatePartial = {
      ...resultExposure,
      feeAmt: response.feeAmt,
      additionalAmt: response.additionalAmt,
      netToMerchantAmt: response.netToMerchantAmt,
      savingsPct: response.savingsPct,
      expectedRet: response.expectedRet,
      expectedRetDisc: response.expectedRetDisc,
      trueRate: response.trueRate,
      trueRateWithComm: response.trueRateWithComm,
      trueRateDisc: response.trueRateDisc,
      trueRateWithCommDisc: response.trueRateWithCommDisc,
      dParam: response.dParam,
      dParamDisc: response.dParamDisc,
      deposits: response.deposits,
      withdrawals: response.withdrawals,
      buyOutAmt: response.buyOutAmt,
    };
    const isFullResponse = (r: ScheduleResponse | ScheduleResponseParams): r is ScheduleResponse => 'days' in response;
    if (isFullResponse(response)) {
      updates.offerForm = {
        days: response.days,
        daysDisc: response.daysDisc,
        withdrAmt: response.withdrAmt,
        fundedAmt: response.amount,
        fixed_cf: response.fixed_cf,
      };
      if (response.amount !== this.offerForm.fundedAmt) {
        this.messageService.add({ severity: 'warn', summary: 'Funded amount has been adjusted' });
      }
    }
    this.updateState(updates);
  }

  private initData() {
    this.mcaPageService
      .getLoadedState()
      .pipe(
        filter(() => !this.offerForm.fundedAmt),
        first(),
      )
      .subscribe(({ mca }) => {
        const updates = ScheduleFunctions.calcLocals(this.state, mca);
        if (updates.additionalAmt < 0) {
          Object.assign(updates, ScheduleFunctions.calcSuggested(updates, mca));
        }
        this.updateState(updates);
      });
  }

  private getTemplate() {
    return this.mcaPageService.get('offerTemplates').find(t => t.id === this.offerForm.templateId);
  }
}
