import { Injectable, inject } from '@angular/core';
import { ApiService } from '@mca/shared/util';

import {
  httpMcaList,
  httpMca,
  httpCreateMca,
  httpMcaDub,
  httpMcaRefreshBalance,
  httpMcaRelated,
  httpMcaQA,
  httpMcaNotes,
  httpMcaAudit,
  httpMcaComponent,
  httpMcaUpdComponent,
  httpMcaStatusStat,
  httpMcaRateTermMap,
  httpMcaDupCheck,
  httpMcaDoc,
  httpMcaTransStat,
  httpMcaLenderPartAgreement,
  httpMcaCommUserSetActive,
  httpMcaCommUserSendOffer,
  httpMcaWorkflow,
  httpMcaRefCompanies,
  httpMcaDocUpdate,
  httpGenerateBalanceLetter,
  httpGenerateACHPage,
  httpGenerateSettlementAgreement,
  httpExposure,
  httpPerformance,
  httpMcaChangeStatus,
  httpMcaGenerateMerchantContract,
  httpMcaCommSetUsers,
  httpMcaDubWithOffers,
  httpMcaNotifyIso,
  httpMcaFindRelated,
  httpMcaViewContract,
  httpLoadInvestorsParticipation,
  httpRequestRateChange,
  httpMcaDataMerchNote,
  httpMcaDataMerchCategories,
  httpMcaDataMerch,
  httpMcaSubmitMerchantContract,
  httpGeneratePaymentHistory,
  httpMcaDba,
  httpGenerateExternalLawyerCollection,
  httpMcaCollectionPaymentReminders,
  httpGenerateDisclosureSubcontract,
  httpGenerateSubcontract,
  httpMcaFundingCallQA,
  httpMcaBankAccount,
  httpMcaHasUCCFee,
  httpMcaCreditCard,
  httpMcaAccountReceivableAssignment,
  httpMcaAccountReceivableUserAssignment,
  httpMcaAccountReceivableGenerateCoverLetter,
  httpMcaAccountReceivableSandCoverLetter,
  httpGenerateContract,
  httpMcaCreditCardCharge,
  httpMcaVoidDocuSignContract,
  httpMcaSendReminder,
  httpMcaAdjustBalance,
  httpMcaReconciliation,
  httpMcaReconciliationWorkflow,
  httpMcaOcrolus,
  httpMcaAllocationCalc,
  httpMerchantBankruptcy,
  httpMerchantBankruptcyKeyDate,
  httpMerchantBankruptcyUpdate,
  httpMerchantBankruptcyKeyDateUpdate,
  httpMcaCalcOfferSchedule,
  httpMcaIsoBonusEligible,
  httpMcaSetRFF,
  httpMcaHeron,
  httpMcaDocGenerateTempLink,
} from './mca-http-points';
import { Observable, of, map } from 'rxjs';
import { DBARec, NoteRec, Owner } from '@mca/shared/domain';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { GridPagination } from '@mca/shared/feature-grid';
import { ReferencesService } from '@mca/references/api';
import { McaDoc, MCARec } from '../entities/mcarec';
import { McaCommisionUser } from '../entities/commisionuser';
import { McaRefCompany } from '../entities/mca-ref-company';
import { MCAStatuses } from '../entities/mca-consts';
import { Bankrec, CreditCard } from '@mca/user/api';
import { RenewalRec } from '../entities/renewalrec';
import { McaAccountReceivable } from '../entities/account-receivables';
import { OfferCalcData, OfferCalcParams } from '../entities/offer';
import { ScheduleResponse } from '../entities/offer-mca-rec';
import { OcrolusSummary } from '../entities/ocrolus';
import { RelatedMcaList } from '../entities/releted-mca';
import { Bankruptcy, KeyDate } from '../entities/bankruptcy';
import { HeronResponse } from '../entities/heron';
import { McaWorkflow } from '../entities/mca-workflow';

export interface DataMerchAction {
  action: 'DataMerchAction';
  added_by: string;
  created_at: string;
  category: string;
  note: string;
}

export interface SetRateRequest {
  mcaid: number;
  sendRequest: boolean;
  comment: string;
  rate_type: number;
  settlement_balance?: number;
}

export interface McaQA {
  answer: string;
  answer_default: string;
  answer_type: 'multiselect' | 'input' | 'select' | 'checkbox' | 'textarea' | 'datepicker';
  answer_values: any[];
  dst_form: string;
  id: number;
  position: number;
  question: string;
  tag: string | null;
}

export interface ReconciliationWorkflow {
  doc_ref_type_id: number;
  dst_form: string;
  end_date: string;
  id: number;
  ref_id: number;
  start_date: string;
}

export interface ReconciliationWorkflowQA {
  workflow: ReconciliationWorkflow;
  answers: McaQA[];
}

export interface RelatedMcaWithUCCFee {
  mca_id: number;
  effective_date: string;
  status: number;
}

@Injectable({
  providedIn: 'root',
})
export class McaService {
  private apiService = inject(ApiService);
  private refService = inject(ReferencesService);

  private _rateTermMap = [];

  getMCAs(query: any, pagination: GridPagination) {
    return this.apiService.post(httpMcaList(), { filter: query, ...pagination });
  }

  getMCA(id: number) {
    return this.apiService.get(httpMca(id));
  }

  saveMCA(id: number, doc: Partial<MCARec>, params = {}) {
    if (!id && doc.toCreatePayload) {
      return this.apiService.put(httpCreateMca(), doc.toCreatePayload());
    }
    return this.apiService.post(httpMca(id), { doc, params });
  }

  deleteMca(id: number) {
    return this.apiService.delete(httpMca(id));
  }

  dupMCA(id: number, dupType: 'duplicate' | 'renew') {
    return this.apiService.post<{
      id: number;
      orig_status_reason_id: number;
      status: number;
    }>(httpMcaDub(id), { dupType });
  }

  dupMCAWithOffers(id: number) {
    return this.apiService.post(httpMcaDubWithOffers(id), { dupType: 'duplicate' });
  }

  refreshBalance(id: number) {
    return this.apiService.post(httpMcaRefreshBalance(id));
  }

  relatedMCAs(id: number) {
    return this.apiService.get<RenewalRec[]>(httpMcaRelated(id));
  }

  getMCAQA(id: number) {
    return this.apiService.get(httpMcaQA(id));
  }

  updateMCAQA(id: number, notes: NoteRec[], category: string) {
    return this.apiService.post(httpMcaQA(id), { category, data: notes });
  }

  getMcaFundingCallQA(id: number) {
    return this.apiService.get<McaQA[]>(httpMcaFundingCallQA(id));
  }

  updateMcaFundingCallQA(
    id: number,
    params: {
      question_id: number;
      answer: string;
    }[],
  ) {
    return this.apiService.put(httpMcaFundingCallQA(id), params);
  }

  getMcaReconciliationWorkflows(id: number) {
    return this.apiService.get<{
      mcaids: [];
      workflow: ReconciliationWorkflow[];
    }>(httpMcaReconciliation(id));
  }

  getReconcWorkflow(id: number, workflowId: number) {
    return this.apiService.get<ReconciliationWorkflowQA>(httpMcaReconciliationWorkflow(id, workflowId));
  }

  createMcaReconciliationWorkflow(id: number) {
    return this.apiService.post<ReconciliationWorkflowQA>(httpMcaReconciliation(id));
  }

  updateMcaReconciliationQA(
    id: number,
    workflowId: number,
    params: {
      answers: {
        question_id?: number;
        answer: { mcaid: number; amount: number }[];
      }[];
      start_date?: string;
    },
  ) {
    return this.apiService.put(httpMcaReconciliationWorkflow(id, workflowId), params);
  }

  deleteReconcWorkflow(id: number, workflowId: number) {
    return this.apiService.delete(httpMcaReconciliationWorkflow(id, workflowId));
  }

  getMCANotes(id: number) {
    return this.apiService.get(httpMcaNotes(id));
  }

  addMCANote(id: number, note: NoteRec) {
    return this.apiService.put(httpMcaNotes(id), note);
  }

  deleteMCANote(id: number) {
    return this.apiService.delete(httpMcaNotes(id));
  }

  updateMCANote(id: number, note: NoteRec) {
    return this.apiService.post(httpMcaNotes(id), note);
  }

  getAudit(id: number) {
    return this.apiService.get(httpMcaAudit(id));
  }

  saveMCAComponent(id: number, comp: string, compdoc: any) {
    return this.apiService.post(httpMcaComponent(id), { component: comp, doc: compdoc });
  }

  updateMCAComponent(mcaid: number, comp: string, id: number, compdoc: any) {
    return this.apiService.post(httpMcaUpdComponent(mcaid, comp, id), compdoc);
  }

  getMCAComponent(mcaid: number, comp: string) {
    return this.apiService.get(httpMcaComponent(mcaid), {
      params: {
        comp,
      },
    });
  }

  getStatusStat() {
    return this.apiService.get(httpMcaStatusStat());
  }

  changeStatus(
    id: number,
    params: {
      status: number;
      first_deposit?: string;
      first_payment?: string;
      generate_monthly_fee?: boolean;
      offerid?: number;
      statusreasonid?: number;
      notifyisosets?: number[];
      has_ucc_fee?: boolean;
      ucc_fee_mca_ids?: number[];
    },
  ) {
    return this.apiService.put(httpMcaChangeStatus(id), params);
  }

  setRFF(
    mcaId: number,
    offerId: number,
    params: {
      mca: {
        status: number;
        first_deposit?: string;
        first_payment?: string;
        generate_monthly_fee?: boolean;
        statusreasonid?: number;
        notifyisosets?: number[];
        has_ucc_fee?: boolean;
        ucc_fee_mca_ids?: number[];
      };
      commissions: { userid: number; commission: number; contract_fee: number }[];
    },
  ) {
    return this.apiService.post(httpMcaSetRFF(mcaId, offerId), params);
  }

  notifyIsoSets(
    id: number,
    params: {
      type: string;
      notifyisosets?: number[];
    },
  ) {
    return this.apiService.post(httpMcaNotifyIso(id), params);
  }

  requestRateChange(params: SetRateRequest) {
    return this.apiService.post(httpRequestRateChange(), params);
  }

  getRateTermMap() {
    if (this._rateTermMap.length) {
      return of(this._rateTermMap);
    }

    return this.apiService.get(httpMcaRateTermMap()).pipe(
      map(data => {
        this._rateTermMap = data;
        this._rateTermMap.sort((o1, o2) => Number(o1['count']) - Number(o2['count']));
        return this._rateTermMap;
      }),
    );
  }

  dupCheck(id: number, dba: DBARec, owners: Owner[]) {
    return this.apiService.post(httpMcaDupCheck(), { id, dba, owners });
  }

  generateMerchantContract(mcaId: number, offerId: number) {
    return this.apiService.post(httpMcaGenerateMerchantContract(mcaId, offerId));
  }

  submitMerchantContract(mcaid: number, offerid: number) {
    return this.apiService.post(httpMcaSubmitMerchantContract(mcaid, offerid));
  }

  voidDocuSignContract(
    mcaid: number,
    offerid: number,
    params: {
      reason: string;
    },
  ) {
    return this.apiService.put(httpMcaVoidDocuSignContract(mcaid, offerid), params);
  }

  sendReminder(mcaid: number, offerid: number) {
    return this.apiService.put(httpMcaSendReminder(mcaid, offerid));
  }

  generateSubcontract(mcaid: number, offerid: number) {
    return this.apiService.post(httpGenerateSubcontract(mcaid, offerid));
  }

  updateMCADoc(mcaid: number, docid: number, data: Partial<McaDoc>) {
    return this.apiService.post(httpMcaDocUpdate(mcaid, docid), data);
  }

  deleteMCADoc(id: number) {
    return this.apiService.delete(httpMcaDoc(id));
  }

  getTransactionSummary(id: number) {
    return this.apiService.get(httpMcaTransStat(id));
  }

  generateLenderAgreement(mid: number, lid: number) {
    return this.apiService.post(httpMcaLenderPartAgreement(mid, lid));
  }

  commUserSetSaveUsers(mcaid: number, setindex: number, users: McaCommisionUser[]) {
    return this.apiService.post(httpMcaCommSetUsers(mcaid, setindex), users);
  }

  commUserSetActive(mcaid: number, setindex: number) {
    return this.apiService.post(httpMcaCommUserSetActive(mcaid, setindex));
  }

  commUserSendOffer(mcaid: number, setindex: number) {
    return this.apiService.post(httpMcaCommUserSendOffer(mcaid, setindex));
  }

  calcOfferSchedule(offer: OfferCalcData, params: OfferCalcParams) {
    return this.apiService.post<ScheduleResponse>(httpMcaCalcOfferSchedule(), { offer, params });
  }

  getMCAWorkFlow(mid: number) {
    return this.apiService.get<McaWorkflow>(httpMcaWorkflow(mid));
  }

  postMCAWorkFlow(mid: number, id: number, taskid: number, status: number, comment: string) {
    return this.apiService.post(httpMcaWorkflow(mid), {
      id,
      taskid,
      status,
      comment,
    });
  }

  saveRefCompanies(mcaid: number, companies: McaRefCompany[]) {
    return this.apiService.post(httpMcaRefCompanies(mcaid), { companies });
  }

  getRefCompanies(mcaid: number) {
    return this.apiService.get(httpMcaRefCompanies(mcaid));
  }

  generateBalanceLetter(mcaid: number, confirmed: boolean) {
    return this.apiService.post(httpGenerateBalanceLetter(mcaid), { confirmed });
  }

  generateContract(mcaid: number) {
    return this.apiService.post(httpGenerateContract(mcaid));
  }

  generatePaymentHistory(mcaid: number, confirmed: boolean) {
    return this.apiService.post(httpGeneratePaymentHistory(mcaid), { confirmed });
  }

  generateDisclosure(mcaid: number) {
    return this.apiService.post(httpGenerateDisclosureSubcontract(mcaid));
  }

  generateExternalLawyerCollection(mcaid: number) {
    return this.apiService.post(httpGenerateExternalLawyerCollection(mcaid));
  }

  generateACHPage(mcaid: number, depPct: number) {
    return this.apiService.post(httpGenerateACHPage(mcaid), { depPct });
  }

  generateSettlementAgreement(
    mcaid: number,
    params: {
      isDefaultedDeal: boolean;
      settlementAmount: number;
      paymentMethod: string;
      isOneTimePayment: boolean;
      initialPaymentDate: string;
      initialPaymentAmount: number;
      recurringPaymentStartDate?: string;
      recurringPaymentAmount?: number;
      recurringPaymentFrequency?: string;
    },
  ) {
    return this.apiService.post(httpGenerateSettlementAgreement(mcaid), params);
  }

  getExposure(mcaid: number) {
    return this.apiService.get<number>(httpExposure(mcaid));
  }

  getPerformance(mcaid: number) {
    return this.apiService.get<any[]>(httpPerformance(mcaid));
  }

  statusInFunded(status?: number) {
    if (status === undefined) {
      return false;
    }
    const mcaStatFunded = this.getFundedStatusList();
    return mcaStatFunded.includes(status);
  }

  statusIsReadyForFunding(status?: number) {
    if (status === undefined) {
      return false;
    }
    return status === this.refService.getStatusId(MCAStatuses.readyForFunding);
  }

  statusIsReadyForFundingOrHigher(status?: number) {
    return (
      !!status &&
      [
        ...this.getFundedStatusList(),
        this.refService.getStatusId(MCAStatuses.completed),
        this.refService.getStatusId(MCAStatuses.rescind),
        this.refService.getStatusId(MCAStatuses.readyForFunding),
      ].includes(status)
    );
  }

  statusIsFundedOrCompleted(status?: number) {
    return (
      !!status &&
      [
        this.refService.getStatusId(MCAStatuses.completed),
        this.refService.getStatusId(MCAStatuses.funded),
        this.refService.getStatusId(MCAStatuses.fundedNA),
      ].includes(status)
    );
  }

  statusIsOfferAccepted(status?: number) {
    return (
      !!status &&
      [
        this.refService.getStatusId(MCAStatuses.readyForFunding),
        this.refService.getStatusId(MCAStatuses.funded),
        this.refService.getStatusId(MCAStatuses.fundedNA),
        this.refService.getStatusId(MCAStatuses.rescind),
        this.refService.getStatusId(MCAStatuses.completed),
      ].includes(status)
    );
  }

  viewMcaContract(id: number) {
    return this.apiService.loadFile(httpMcaViewContract(id));
  }

  private getFundedStatusList() {
    return [
      this.refService.getStatusId(MCAStatuses.funded),
      this.refService.getStatusId(MCAStatuses.fundedNA),
      this.refService.getStatusId(MCAStatuses.fundedEdit),
      this.refService.getStatusId(MCAStatuses.fundedOther),
    ];
  }

  getRelatedMca(
    mcaid: number,
    params?: {
      status: number[];
    },
  ) {
    return this.apiService.get<RelatedMcaList>(httpMcaFindRelated(mcaid), { params });
  }

  getAllocationCalc(transId: number, params: { fee?: number }) {
    return this.apiService.get<
      {
        mcaid: number;
        amount: number;
        fee: number;
      }[]
    >(httpMcaAllocationCalc(transId), { params });
  }

  loadInvestorsParticipation(mcaid: number) {
    return this.apiService.get(httpLoadInvestorsParticipation(mcaid));
  }

  getMcaDataMerch(
    mcaid: number,
    params?: {
      category: string;
      note?: string;
    },
  ): Observable<any> {
    return this.apiService.get(httpMcaDataMerch(mcaid), params);
  }

  getMcaDataMerchRegisterCompany(
    mcaid: number,
    params?: {
      category: string;
      note: string;
    },
  ): Observable<DataMerchAction[]> {
    return this.apiService.post(httpMcaDataMerch(mcaid), params);
  }

  getMcaDataMerchCreateNote(
    mcaid: number,
    params?: {
      category: string;
      note: string;
    },
  ): Observable<DataMerchAction[]> {
    return this.apiService.post(httpMcaDataMerchNote(mcaid), params);
  }

  getMcaDataMerchCategories(): Observable<any[]> {
    return this.apiService.get(httpMcaDataMerchCategories());
  }

  getMcaDbaList(params: { legalName?: string; taxId?: string }) {
    return this.apiService.get<
      {
        dbaRec: DBARec;
        _id: number;
      }[]
    >(httpMcaDba(), { params });
  }

  getCollectionPaymentReminders(mcaId: number) {
    return this.apiService.get(httpMcaCollectionPaymentReminders(mcaId));
  }

  getMcaAccountReceivables(mcaId: number) {
    return this.apiService.get<McaAccountReceivable[]>(httpMcaAccountReceivableAssignment(mcaId));
  }

  setMcaAccountReceivables(mcaId: number, userIds: number[]) {
    return this.apiService.post(httpMcaAccountReceivableAssignment(mcaId), userIds);
  }

  updateMcaAccountReceivable(mcaId: number, userId: number, body: Partial<McaAccountReceivable>) {
    return this.apiService.put(httpMcaAccountReceivableUserAssignment(mcaId, userId), body);
  }

  generateARCoverLetter(mcaId: number, ar_user_id: number) {
    return this.apiService.post(httpMcaAccountReceivableGenerateCoverLetter(mcaId), { ar_user_id });
  }

  sendARCoverLetter(mcaId: number, ar_user_id: number) {
    return this.apiService.post(httpMcaAccountReceivableSandCoverLetter(mcaId), { ar_user_id });
  }

  getMcaBankAccounts(mcaId: number) {
    return this.apiService.get<Bankrec[]>(httpMcaBankAccount(mcaId));
  }

  createMcaBankAccount(mcaId: number, body: Bankrec) {
    return this.apiService.post<{
      id: number;
    }>(httpMcaBankAccount(mcaId), body);
  }

  updateMcaBankAccount(mcaId: number, body: Bankrec) {
    return this.apiService.put(httpMcaBankAccount(mcaId, body.id), body);
  }

  deleteMcaBankAccount(mcaId: number, accountId: number) {
    return this.apiService.delete(httpMcaBankAccount(mcaId, accountId));
  }

  getMcaCreditCards(mcaId: number) {
    return this.apiService.get<CreditCard[]>(httpMcaCreditCard(mcaId));
  }

  createMcaCreditCard(mcaId: number, body: CreditCard) {
    return this.apiService.post<{
      id: number;
    }>(httpMcaCreditCard(mcaId), body);
  }

  updateMcaCreditCard(mcaId: number, data: CreditCard) {
    const { id, ...body } = data;
    return this.apiService.put(httpMcaCreditCard(mcaId, id), body);
  }

  deleteMcaCreditCard(mcaId: number, accountId: number) {
    return this.apiService.delete(httpMcaCreditCard(mcaId, accountId));
  }

  chargeMcaCreditCard(params: { cc_id: number; venue: string; amount: number; comment: string; mcaid: number }) {
    return this.apiService.post<{
      id: number;
      result: {
        approved: boolean;
        text: string;
      };
    }>(httpMcaCreditCardCharge(), params);
  }

  getUCCFee(taxId: string) {
    return this.apiService.get<RelatedMcaWithUCCFee[]>(httpMcaHasUCCFee(taxId));
  }

  adjustBalance(
    mcaId: number,
    body: {
      discountAdjustment?: number;
      feeWaiverAdjustment?: number;
      paymentAdjustment?: number;
      fees?: Record<string, number>[];
    },
  ) {
    return this.apiService.post<
      {
        id: number;
      }[]
    >(httpMcaAdjustBalance(mcaId), body);
  }

  getOcrolusData(mcaId: number) {
    return this.apiService.get<OcrolusSummary>(httpMcaOcrolus(mcaId));
  }

  sendRequestToOcrolus(mcaId: number) {
    return this.apiService.post(httpMcaOcrolus(mcaId));
  }

  getHeronData(mcaId: number) {
    return this.apiService.get<HeronResponse>(httpMcaHeron(mcaId));
  }

  sendRequestToHeron(mcaId: number) {
    return this.apiService.post(httpMcaHeron(mcaId));
  }

  getMcaIsoBonusEligible(mcaId: number, offerId: number) {
    return this.apiService.get(httpMcaIsoBonusEligible(mcaId, offerId));
  }

  getBankruptcy(subject: string, ein: string) {
    return this.apiService.get<Bankruptcy[]>(httpMerchantBankruptcy(subject, ein));
  }

  updateBankruptcy(subject: string, ein: string, bankruptcyId: number, params: Partial<Bankruptcy>) {
    return this.apiService.put(httpMerchantBankruptcyUpdate(subject, ein, bankruptcyId), params);
  }

  deleteBankruptcy(subject: string, ein: string, bankruptcyId: number) {
    return this.apiService.delete(httpMerchantBankruptcyUpdate(subject, ein, bankruptcyId));
  }

  createBankruptcy(subject: string, ein: string, params: Partial<Bankruptcy>) {
    const additionalField = subject === 'merchant' ? { ein } : { ssn: ein };

    return this.apiService.post(httpMerchantBankruptcy(subject, ein), { ...params, ...additionalField });
  }

  createBankruptcyKeyDates(subject: string, ein: string, bankruptcyId: number, params: KeyDate) {
    return this.apiService.post(httpMerchantBankruptcyKeyDate(subject, ein, bankruptcyId), params);
  }

  updateBankruptcyKeyDates(subject: string, ein: string, bankruptcyId: number, keyDateId: number, params: KeyDate) {
    return this.apiService.put(httpMerchantBankruptcyKeyDateUpdate(subject, ein, bankruptcyId, keyDateId), params);
  }

  deleteBankruptcyKeyDates(subject: string, ein: string, bankruptcyId: number, keyDateId: number) {
    return this.apiService.delete(httpMerchantBankruptcyKeyDateUpdate(subject, ein, bankruptcyId, keyDateId));
  }

  getMcaDocTempLink(mcaid: number, docid: number, params: { days: number }) {
    return this.apiService.get(httpMcaDocGenerateTempLink(mcaid, docid), { params });
  }
}
