import { Injectable, InjectionToken, inject } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DialogService } from '@mca/shared/feature-dialog';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FieldConfig, updateDynamicFormFields } from '@mca/shared/feature-dynamic-form';
import { MessageService } from 'primeng/api';
import { EMPTY, Observable, of, pipe } from 'rxjs';
import { catchError, filter, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { ReferencesService } from '@mca/references/api';
import { McaOffer, OfferOutstandings } from '../../entities/offer';
import { McaPageService } from '../mca-page.service';
import { OfferService } from '../../infrastructure/offer.service';
import { McaService } from '../../infrastructure/mca.service';
import { MCAStatuses } from '../../entities/mca-consts';
import { ComponentType } from '@angular/cdk/portal';
import { OfferStatus } from '@mca/shared/domain';
import { RxState } from '@rx-angular/state';
import { McaScheduleService } from './schedule/mca-schedule.service';

export const offerVerifyModalToken = new InjectionToken('offer-verify-modal');

export const offerVerifyModalFields: FieldConfig[] = [
  {
    name: 'details',
    type: 'component',
  },
];

interface SendResponse {
  sent: boolean;
  outstandings: OfferOutstandings;
}

interface State {
  offerActionInProgress: {
    setId: number;
    offerId: number;
  } | null;
}

@Injectable()
export class OfferActionsService extends RxState<State> {
  private messageService = inject(MessageService);
  private mcaPageService = inject(McaPageService);
  private mcaScheduleService = inject(McaScheduleService);
  private offerService = inject(OfferService);
  private dialogService = inject(DialogService);
  private mcaService = inject(McaService);
  private refService = inject(ReferencesService);
  private offerVerifyModal = inject<ComponentType<any>>(offerVerifyModalToken);

  private get mca() {
    return this.mcaPageService.get('mca');
  }

  constructor() {
    super();
    this.set({ offerActionInProgress: null });
  }

  isOfferInProgress(setId: number, offerId: number) {
    return this.select('offerActionInProgress').pipe(map(v => v?.setId === setId && (!offerId || v.offerId === offerId)));
  }

  sendOffer(setId: number, offerId: number) {
    this.set({ offerActionInProgress: { setId, offerId } });
    this.offerService
      .sendOffer(this.mca.id, [offerId], false)
      .pipe(this.tapOfferVerifyModal(this.offerService.sendOffer(this.mca.id, [offerId], true)))
      .subscribe(result => {
        if (result.sent) {
          this.messageService.add({
            severity: 'success',
            summary: 'Offer was sent',
          });
          return;
        }
        this.messageService.add({
          severity: 'error',
          summary: 'Cannot send offer',
        });
      });
  }

  loadOffer(setId: number, offerId: number) {
    this.set({ offerActionInProgress: { setId, offerId } });
    this.offerService
      .getOffer(this.mca.id, offerId)
      .pipe(
        finalize(() => {
          this.set({ offerActionInProgress: null });
          this.dialogService.closeModal();
        }),
      )
      .subscribe(response => {
        const offer = new McaOffer();
        const { schedule, ...offerData } = response;
        Object.assign(offer, offerData);
        this.mcaPageService.set({ loadedOffers: [offer] });
        this.mcaScheduleService.getScheduleByOffer(offer).applyCalcResponse(schedule);
      });
  }

  expireOffer(setId: number, offerId: number) {
    this.set({ offerActionInProgress: { setId, offerId } });
    const offer = this.mcaPageService.getOffer(offerId) ?? {};
    const updateOffer = { ...offer, status: OfferStatus.Expired };
    this.offerService
      .expireOffer(this.mca.id, offerId)
      .pipe(
        tap(() => {
          if (this.mcaPageService.get('loadedOffers')?.some(o => o.id === offerId)) {
            this.mcaPageService.set({ loadedOffers: undefined });
          }
        }),
        switchMap(() => this.mcaPageService.loadOffers$()),
        finalize(() => this.set({ offerActionInProgress: null })),
      )
      .subscribe(() => {
        Object.assign(offer, updateOffer);
        this.mcaPageService.set({ offers: [...this.mcaPageService.get('offers')] });
        this.messageService.add({
          severity: 'success',
          summary: 'Offer deactivated',
        });
      });
  }

  expireSelected(setId: number, ids: number[]) {
    this.set({ offerActionInProgress: { setId, offerId: 0 } });
    this.offerService
      .expireOffers(this.mca.id, ids)
      .pipe(
        switchMap(() => this.mcaPageService.loadOffers$()),
        finalize(() => {
          this.set({ offerActionInProgress: null });
          this.dialogService.closeModal();
        }),
      )
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Selected offers have been expired',
        });
      });
  }

  sendSelected(setId: number, ids: number[]) {
    this.set({ offerActionInProgress: { setId, offerId: 0 } });
    this.offerService
      .sendOffer(this.mca.id, ids, false)
      .pipe(this.tapOfferVerifyModal(this.offerService.sendOffer(this.mca.id, ids, true)))
      .subscribe(result => {
        if (result.sent) {
          this.messageService.add({
            severity: 'success',
            summary: 'Selected offers have been sent',
          });
          return;
        }
        this.messageService.add({
          severity: 'error',
          summary: 'Cannot send offers',
        });
      });
  }

  copyToAnotherSet(offerIds: number[], userSets: number[]) {
    this.offerService
      .copyOffers(this.mca.id, offerIds, userSets)
      .pipe(catchError(() => EMPTY))
      .subscribe(({ message }) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Selected offers have been processed',
          detail: message || '',
          styleClass: 'pre-lined',
          life: message ? 20000 : 3000,
          closable: !!message,
        });
        this.mcaPageService.refreshData();
      });
  }

  sendContract(setId: number, offerId: number) {
    this.set({ offerActionInProgress: { setId, offerId } });
    this.offerService
      .sendContract(this.mca.id, offerId)
      .pipe(this.tapCatchFinalize())
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Contract sent',
        });
        this.mcaPageService.refreshData();
      });
  }

  requestContract(setId: number, offerId: number) {
    this.set({ offerActionInProgress: { setId, offerId } });
    this.offerService
      .contractRequested(this.mca.id, offerId)
      .pipe(this.tapCatchFinalize())
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Contract requested',
        });
        this.mcaPageService.refreshData();
      });
  }

  contractSigned(setId: number, offerId: number) {
    this.set({ offerActionInProgress: { setId, offerId } });
    this.offerService
      .contractSigned(this.mca.id, offerId)
      .pipe(this.tapCatchFinalize())
      .subscribe(({ message }) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Contract signed',
          detail: message || '',
          styleClass: 'pre-lined',
          life: message ? 20000 : 3000,
          closable: !!message,
        });
        this.mcaPageService.refreshData();
        this.dialogService.closeModal();
      });
  }

  contractSignedExclusive(setId: number, offerId: number) {
    this.set({ offerActionInProgress: { setId, offerId } });
    this.offerService
      .contractSignedExclusive(this.mca.id, offerId)
      .pipe(this.tapCatchFinalize())
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Contract signed (exclusive)',
        });
        this.mcaPageService.refreshData();
      });
  }

  contractReadyFF(
    setId: number,
    offerId: number,
    firstDeposit: string,
    firstPayment: string,
    has_ucc_fee: boolean,
    ucc_fee_mca_ids: number[],
    generate_monthly_fee: boolean,
    commissions: { userid: number; commission: number; contract_fee: number }[],
  ) {
    this.set({ offerActionInProgress: { setId, offerId } });
    return this.mcaService
      .setRFF(this.mca.id, offerId, {
        mca: {
          status: this.refService.getStatusId(MCAStatuses.readyForFunding),
          first_deposit: firstDeposit,
          first_payment: firstPayment,
          has_ucc_fee,
          ucc_fee_mca_ids,
          generate_monthly_fee,
        },
        commissions,
      })
      .pipe(this.tapCatchFinalize());
  }

  generateCOJ(setId: number, offerId: number) {
    this.set({ offerActionInProgress: { setId, offerId } });
    this.offerService
      .generateCOJ(this.mca.id, offerId)
      .pipe(finalize(() => this.set({ offerActionInProgress: null })))
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Generate COJ Finished',
        });
      });
  }

  generateAppA(setId: number, offerId: number) {
    this.set({ offerActionInProgress: { setId, offerId } });
    this.offerService
      .generateAppA(this.mca.id, offerId)
      .pipe(finalize(() => this.set({ offerActionInProgress: null })))
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Generate App A Finished',
        });
      });
  }

  generateExhibitA(setId: number, offerId: number) {
    this.set({ offerActionInProgress: { setId, offerId } });
    this.offerService
      .generateExhibitA(this.mca.id, offerId)
      .pipe(finalize(() => this.set({ offerActionInProgress: null })))
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Generate Exhibit A Finished',
        });
      });
  }

  generateConsolidationGrid(setId: number, offerId: number) {
    this.set({ offerActionInProgress: { setId, offerId } });
    this.offerService
      .generateConsolidationGrid(this.mca.id, offerId)
      .pipe(finalize(() => this.set({ offerActionInProgress: null })))
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Generate Consolidation Grid - Finished',
        });
      });
  }

  private tapCatchFinalize<T>() {
    return pipe(
      catchError(e => {
        // handled already as http error
        console.error(e);
        return EMPTY;
      }),
      finalize<T>(() => this.set({ offerActionInProgress: null })),
    );
  }

  private tapOfferVerifyModal(forcedSendRequest: Observable<SendResponse>) {
    return pipe(
      switchMap((response: SendResponse) => {
        if (response.outstandings?.match === false) {
          return this.showVerifyModal(response, forcedSendRequest);
        }
        return of(response);
      }),
      switchMap(dialogData => this.mcaPageService.loadOffers$().pipe(map(() => dialogData))),
      finalize(() => {
        this.set({ offerActionInProgress: null });
        this.dialogService.closeModal();
      }),
    );
  }

  private showVerifyModal(response: SendResponse, forcedSendRequest: Observable<SendResponse>) {
    updateDynamicFormFields(offerVerifyModalFields, [
      { name: 'details', component: this.offerVerifyModal, componentInputs: { outstandings: response.outstandings } },
    ]);
    return this.dialogService
      .open({
        title: 'Outstanding positions warnings',
        fields: offerVerifyModalFields,
        confirmLabel: 'Send anyway',
        cancelLabel: 'Cancel',
        extraButtons: [],
        styleClass: 'dialog__size-lg',
      })
      .pipe(
        first(),
        filter(Boolean),
        switchMap(() => forcedSendRequest),
      );
  }
}
