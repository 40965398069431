import { Injectable, inject } from '@angular/core';
import { OCPayment } from '@mca/shared/domain';
import {
  McaTransactionService,
  TransTypeAcro,
  Transactions,
  TransactionsService,
  adjustDate,
  transDefenitions,
} from '@mca/transaction/api';
import { RxState } from '@rx-angular/state';
import { SelectItem } from 'primeng/api';
import { filter, first, forkJoin, map, of, shareReplay, switchMap, tap, throwError } from 'rxjs';
import { MCAProgramTypes } from '../entities/mca-consts';
import { MCARec } from '../entities/mcarec';
import { ExposureRec } from '../entities/offer-mca-rec';
import { McaService } from '../infrastructure/mca.service';
import { McaPageService } from './mca-page.service';
import { McaScheduleService } from './underwriting/schedule/mca-schedule.service';
import { selectSlice } from '@rx-angular/state/selections';

interface ServiceState {
  deposits: OCPayment[];
  withdrawals: OCPayment[];
  exposure: ExposureRec;
  transStatuses: SelectItem<number>[];
  transTypes: SelectItem<number>[];
  transSubtypes: SelectItem<number>[];
  transVenues: SelectItem<string>[];
}

@Injectable()
export class McaTransactionsPageService {
  private mcaPageService = inject(McaPageService);
  private mcaTransService = inject(McaTransactionService);
  private mcaService = inject(McaService);
  private mcaScheduleService = inject(McaScheduleService);
  private transactionService = inject(TransactionsService);
  state = inject<RxState<ServiceState>>(RxState);

  private get mca() {
    return this.mcaPageService.get('mca');
  }

  // ensures that initState is executed only after subscription to observable
  lazyState$ = of(void 0).pipe(
    tap(() => this.initState()),
    shareReplay(1),
    switchMap(() => this.state.select()),
  );

  initState() {
    const fetchTransactions$ = this.loadTransactionsData().pipe(map(v => this.dataToTransactions(v)));
    this.state.connect(fetchTransactions$);
    this.mcaScheduleService.updateSchedules$().subscribe();
    const singleOfferExposure$ = this.mcaScheduleService.schedules$.pipe(
      filter(schedules => schedules.length === 1),
      switchMap(schedules =>
        schedules[0].state$.pipe(
          selectSlice(['exposureRec']),
          map(({ exposureRec }) => exposureRec),
        ),
      ),
    );
    this.state.connect('exposure', singleOfferExposure$);
    this.state.connect(this.loadOptionValues());
  }

  getOptionValues() {
    return this.lazyState$.pipe(selectSlice(['transStatuses', 'transTypes', 'transSubtypes', 'transVenues']), first());
  }

  saveSchedule(mca: MCARec, sched: Transactions[]) {
    if (!this.mcaService.statusInFunded(mca.position.status ?? 0)) {
      return throwError(() => new Error('Status has to be Funded/Funded NA to create transactions'));
    } else if (sched.length > 0) {
      sched.forEach(t => (t.ammount = Number(t.ammount)));
      return this.mcaTransService.createTransactionsBulk(sched);
    }
    return of([]);
  }

  private loadOptionValues() {
    return this.transactionService.getConstants().pipe(
      map(res => {
        const transStatuses = Object.entries(res.transStatuses).map(([k, v]) => ({ label: v, value: +k }));
        const transTypes = Object.entries(res.transTypes).map(([k, v]) => ({ label: v, value: +k }));
        const transSubtypes = Object.entries(res.transSubTypes).map(([k, v]) => ({
          label: v.name ?? '',
          value: +k,
        }));
        const transVenues = [{ label: '', value: '' }, ...res.transVenues.map(value => ({ label: value, value }))];
        return { transStatuses, transTypes, transSubtypes, transVenues };
      }),
    );
  }

  private loadTransactionsData() {
    const payments = this.mcaTransService.getTransactionList({ mcaid: this.mca.id, transtype: TransTypeAcro.payment });
    const toMerchant = this.mcaTransService.getTransactionList({ mcaid: this.mca.id, transtype: TransTypeAcro.dtm });
    const consolidation = this.mcaTransService.getTransactionList({ mcaid: this.mca.id, transtype: TransTypeAcro.dcon });
    const outstanding = this.mcaTransService.getTransactionList({ mcaid: this.mca.id, transtype: TransTypeAcro.dto });
    return forkJoin([payments, toMerchant, consolidation, outstanding]);
  }

  private dataToTransactions([payments, toMerchant, consolidation, outstanding]: Transactions[][]) {
    // withdrawals
    adjustDate(payments);
    const withdrawals = payments.map(v => new OCPayment(v.effectivedate, v.ammount, transDefenitions.isPaymentProcessed(v.status)));
    // deposits
    adjustDate(toMerchant);
    const deposits = [...toMerchant, ...consolidation].map(
      v => new OCPayment(v.effectivedate, v.ammount, transDefenitions.isTransactionProcessed(v.status)),
    );
    const dtoAmt = outstanding.reduce((acc, v) => acc + (v.ammount ?? 0), 0);
    if (deposits.length > 0) {
      this.mcaScheduleService.schedules.forEach(s => {
        if (s.offerForm.program === MCAProgramTypes.incrementalDeal) {
          const incrementFee = s.state.feeAmt / deposits.length;
          deposits.forEach(d => (d.ammount += incrementFee));
        } else {
          deposits[0].ammount += s.state.feeAmt + dtoAmt;
        }
      });
    }
    return {
      deposits,
      withdrawals,
    };
  }
}
