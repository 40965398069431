import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HolidaysService, ReferencesService, ReferencesServiceConsts } from '@mca/references/api';
import { AppConstantsService, NoteRec } from '@mca/shared/domain';
import { ApiService, LazyService, environmentToken } from '@mca/shared/util';
import { TransactionsService } from '@mca/transaction/api';
import { UserService } from '@mca/user/api';
import { RxState } from '@rx-angular/state';
import { toDate } from 'date-fns';
import {
  EMPTY,
  auditTime,
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  first,
  forkJoin,
  from,
  map,
  of,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { McaAccountingBalances } from '../entities/mca-accounting-balances';
import { MCAStatuses } from '../entities/mca-consts';
import { MCARec, getMcaVenue, mcaLoad, mcaLoadCommUsers } from '../entities/mcarec';
import { McaOffer, OfferTemplate } from '../entities/offer';
import { httpMcaAccountingBalances } from '../infrastructure/mca-http-points';
import { McaService } from '../infrastructure/mca.service';
import { OfferService } from '../infrastructure/offer.service';
import { Industry } from '@mca/references/domain';

export interface McaPageState {
  loaded: boolean;
  mca: MCARec;
  sets: any[];
  offers: McaOffer[];
  loadedOffers?: McaOffer[];
  fundingVenues: {
    [key: string]: string;
  };
  refConstants: ReferencesServiceConsts;
  industries: any[];
  offerTemplates: OfferTemplate[];
}

@Injectable()
export class McaPageService extends RxState<McaPageState> {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private mcaService = inject(McaService);
  private userService = inject(UserService);
  private refService = inject(ReferencesService);
  private constSvcs = inject(AppConstantsService);
  private offerService = inject(OfferService);
  private transService = inject(TransactionsService);
  private holidaysService = inject(HolidaysService);
  private apiService = inject(ApiService);
  private lazyService = inject(LazyService);
  private env = inject(environmentToken);

  private requiredDataDefaults: Partial<McaPageState> = {
    fundingVenues: undefined,
    refConstants: undefined,
    offers: undefined,
  };
  private get requiredDataDefaultValues() {
    return Object.entries(this.requiredDataDefaults).reduce((acc, [k, v]) => Object.assign(acc, { [k]: v }), {});
  }

  constructor() {
    super();
    if (this.route.snapshot.paramMap.get('offer')) {
      this.requiredDataDefaults.loadedOffers = undefined;
    }
    this.resetCache();
    this.watchRouter();
    this.watchMca();
    this.watchOffers();
    this.fetchConstants();
  }

  getState() {
    return this.select().pipe(auditTime(20));
  }

  getLoadedState() {
    return this.getState().pipe(filter(state => state.loaded));
  }

  refreshData() {
    this.resetCache();
    this.load(this.get('mca').id);
  }

  loadNotes() {
    const mca = this.get('mca');
    if (!mca?.id) {
      return;
    }
    this.mcaService.getMCANotes(mca.id).subscribe((notes: NoteRec[]) => {
      mca.noteRecs = notes ?? [];
    });
  }

  loadOffers$() {
    return this.offerService.getOffers(this.get('mca').id).pipe(
      map(offers =>
        offers.map(offerData => {
          const offer = new McaOffer();
          return Object.assign(offer, offerData);
        }),
      ),
      tap(offers => this.set({ offers })),
    );
  }

  loadOffers() {
    this.loadOffers$().subscribe();
  }

  getOffer(offerId: number) {
    const offers = this.get('offers');
    return offers.find(o => o.id === offerId);
  }

  getVenue(defaultVenue?: number) {
    let venueId: number | undefined = 0;
    const mca = this.get('mca');
    if (
      this.mcaService.statusInFunded(mca.position.status) ||
      this.refService.getStatusId(MCAStatuses.readyForFunding) === mca.position.status
    ) {
      venueId = getMcaVenue(mca, defaultVenue);
      if (!venueId) {
        throw new Error('Deal has no active ISO with Venue');
      }
    } else {
      const offer = this.get('loadedOffers')?.[0];
      venueId = offer?.commissions[0]?.venueid ?? defaultVenue;
      if (!venueId) {
        throw new Error('Load offer to get Venue for deal');
      }
    }
    return venueId;
  }

  getAccountingBalances() {
    this.lazyService.deleteCache('mca-accounting-balances');
    return this.getLazyAccountingBalances();
  }

  getLazyAccountingBalances() {
    return this.lazyService.loadOnce<McaAccountingBalances>('mca-accounting-balances', this.loadAccountingBalances.bind(this));
  }

  getLazyIndustries() {
    return this.lazyService.loadOnce<Industry[]>('mca-industries', () =>
      this.refService.listReferenceRecord<Industry[]>('industry').pipe(catchError(() => EMPTY)),
    );
  }

  getIndustry() {
    return this.getLazyIndustries().pipe(
      map(industries => industries?.find(industry => industry.id === this.get('mca').dbaRec?.industryClassification)),
    );
  }

  private resetCache() {
    this.lazyService.deleteCache('mca-accounting-balances');
  }

  private loadAccountingBalances() {
    return this.getLoadedState().pipe(
      first(),
      switchMap(state => this.apiService.get<McaAccountingBalances>(httpMcaAccountingBalances(state.mca.id))),
      catchError(() => of({} as McaAccountingBalances)),
    );
  }

  private fetchConstants() {
    const refConstants$ = this.refService.getConsts();
    const fundingVenues$ = this.constSvcs
      .getVenues()
      .pipe(
        map(venues =>
          Object.fromEntries(
            Object.entries(venues).filter(([id]) => !this.env.fundingVenues.length || this.env.fundingVenues.includes(+id)),
          ),
        ),
      );
    const mcaConst$ = this.transService.getConstants();
    const holiday$ = this.holidaysService.loadedHolidays$.pipe(take(1));
    const mcaStatuses$ = this.refService.getStatuses();
    const offerTemplates$ = this.offerService.getOfferTemplates().pipe(catchError(() => []));

    // make sure that services fetched their constants
    this.connect(
      forkJoin([refConstants$, fundingVenues$, offerTemplates$, mcaConst$, holiday$, mcaStatuses$]).pipe(
        map(([refConstants, fundingVenues, offerTemplates]) => ({ refConstants, fundingVenues, offerTemplates })),
      ),
    );
  }

  private watchRouter() {
    this.hold(
      this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        startWith({} as NavigationEnd),
        map(() => this.route.snapshot.paramMap.get('mcaId')),
        distinctUntilChanged(),
      ),
      mcaId => {
        if (!mcaId || mcaId === 'new') {
          this.initNewMca();
          return;
        }
        this.load(+mcaId);
      },
    );
  }

  private watchMca() {
    this.hold(
      this.select().pipe(
        distinctUntilKeyChanged('mca'),
        filter(state => !!state.mca),
      ),
      () => {
        this.fetchConstants();
        this.loadOffers();
        this.loadNotes();
      },
    );
  }

  private watchOffers() {
    // update loaded offer data when offers updated
    // do it in place to avoid side effects for actual offer load
    this.hold(this.select('offers'), offers => {
      const loadedOffers = this.get('loadedOffers');
      loadedOffers?.forEach(loadedOffer => {
        const offer = offers.find(({ id }) => id === loadedOffer.id) as McaOffer;
        if (offer) {
          Object.assign(loadedOffer, offer);
        }
      });
    });
  }

  private watchLoadedOnce() {
    this.set({ loaded: false, ...this.requiredDataDefaultValues });
    this.select()
      .pipe(
        map(state => !Object.keys(this.requiredDataDefaults).some(key => !state[key as keyof McaPageState])),
        filter(Boolean),
        first(),
      )
      .subscribe(() => this.set({ loaded: true }));
  }

  private load(id: number) {
    this.watchLoadedOnce();
    const mca$ = this.mcaService.getMCA(id);
    const comUser$ = from(this.mcaService.getMCAComponent(id, 'mcacommisionuser'));
    const comUserList$ = this.userService.getCache();
    forkJoin([mca$, comUser$, comUserList$])
      .pipe(catchError(() => EMPTY))
      .subscribe(([mca, comUser, userList]) => {
        mcaLoad(mca);
        this.updatePaymentsStartDate(mca);
        mcaLoadCommUsers(mca, comUser.sets, userList);
        this.set({ mca, sets: comUser.sets });
      });
  }

  private initNewMca() {
    const mca = new MCARec();
    this.updatePaymentsStartDate(mca);
    this.mcaService.saveMCA(0, mca).subscribe(({ id }) => {
      if (id) {
        this.load(id);
        this.router.navigate(['/mcaedit', id]);
      }
    });
  }

  private updatePaymentsStartDate(mca: MCARec) {
    if (!mca.depStartDate || this.mcaService.statusIsReadyForFundingOrHigher(mca.position?.status)) {
      return;
    }
    const startDate = this.holidaysService.addBusinessDays(mca.depStartDate, 1);
    mca.paybackStartDate = toDate(startDate);
  }
}
