import { SelectItem } from 'primeng/api';

export class McaCommisionUser {
  id = 0;
  userid = 0;
  baseindicator = 1;
  paystrategy = PayStrategy.increment;
  commisionamt = 0;
  commisionpct = 0;
  setindex = 0;
  active = false;
  dealvenue = 1;
  contract_fee_allocation = 0;
  cfcomm_on_last_incr = true;
  created_at?: string | null;
}

// for reference only, there is corresponding value in refConstants
export enum PayStrategy {
  increment = 1,
  firstAll,
  first50Last50,
  first8,
  every4incr,
}
export const payStrategyLevel = [
  PayStrategy.firstAll,
  PayStrategy.first8,
  PayStrategy.first50Last50,
  PayStrategy.every4incr,
  PayStrategy.increment,
];
export const sortPayStrategyOptionsByLevel = (options: SelectItem<PayStrategy>[]) =>
  options.sort((a, b) => payStrategyLevel.indexOf(a.value) - payStrategyLevel.indexOf(b.value));
export const payStrategyAllowedSelection: Record<PayStrategy, PayStrategy[]> = {
  [PayStrategy.increment]: [],
  [PayStrategy.first8]: [PayStrategy.first8, PayStrategy.increment],
  [PayStrategy.first50Last50]: [PayStrategy.first50Last50, PayStrategy.increment],
  [PayStrategy.every4incr]: [PayStrategy.every4incr, PayStrategy.increment],
  [PayStrategy.firstAll]: [],
};
