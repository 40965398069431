import { inject, Injectable, Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { dataentryMcaStatuses, PermissionsService } from '@mca/auth/api';
import { Industry, ReferencesService } from '@mca/references/api';
import { DBARec } from '@mca/shared/domain';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DialogService } from '@mca/shared/feature-dialog';
import { formatISO, intervalToDuration, toDate } from 'date-fns';
import { MessageService } from 'primeng/api';
import { filter, finalize, map } from 'rxjs';
import { McaService } from '../infrastructure/mca.service';
import { MerchantApplyService } from './merchant-apply.service';
import { MerchantPageStateImpl } from './merchant-page-state';

@Injectable()
export class MerchantPageService extends MerchantPageStateImpl {
  private mcaService = inject(McaService);
  protected permissionsService = inject(PermissionsService);
  private route = inject(ActivatedRoute);
  private refService = inject(ReferencesService);
  private messageService = inject(MessageService);
  private dialogService = inject(DialogService);

  get mca() {
    return this.get('mca');
  }
  get merchant() {
    return this.get('merchant');
  }

  private applyService = new MerchantApplyService(this, this.permissionsService, this.dialogService);
  private dialogComponent?: Type<any>;

  constructor() {
    super();
    this.loadData();
  }

  private buildSaveMerchantRequest() {
    const body = {
      ...this.merchant,
      businessStartDate: this.merchant.businessStartDate && formatISO(this.merchant.businessStartDate, { representation: 'date' }),
    };
    const request$ = this.mcaService.saveMCAComponent(this.mca.id, 'dbaRec', body);
    this.set({ loading: true });
    return request$.pipe(finalize(() => this.set({ loading: false, merchantFormDisabled: true, isSaveMerchantInProgress: false })));
  }

  onSaveMerchant() {
    this.set({ isSaveMerchantInProgress: true });
    if (!this.validate()) {
      this.set({ isSaveMerchantInProgress: false });
      return;
    }
    this.buildSaveMerchantRequest().subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: 'DBA information updated',
      });
    });
  }

  onAllowEdit(changeDisabled: 'merchantFormDisabled' | 'refCompaniesDisabled', value: boolean) {
    this.set({ [changeDisabled]: !value });
  }

  getYearsInBusiness(fromDate?: Date) {
    const today = new Date();
    if (!fromDate || today < fromDate) {
      return '';
    }
    const startDate = toDate(fromDate);
    const duration = intervalToDuration({ start: startDate, end: today });
    const years = duration.years ? `${duration.years} year${duration.years === 1 ? '' : 's'}` : '';
    const months = duration.months ? `${duration.months} month${duration.months === 1 ? '' : 's'}` : '';
    return `${years} ${months}`;
  }

  syncMerchantAddressState() {
    this.merchant.stateOfIncorporation ||= this.merchant.address.state;
    this.merchant.address.state ||= this.merchant.stateOfIncorporation;
  }

  applySelectedMerchant(dialogComponent: Type<any>, fieldName: 'legalName' | 'taxId', selectedMerchant: DBARec, selectedValue: string) {
    this.dialogComponent = dialogComponent;
    this.applyService.applySelectedMerchant(fieldName, selectedMerchant, selectedValue);
    this.set({ lastAppliedMerchant: selectedMerchant });
  }

  openMergeDialog() {
    const { businessTypeOptions, industryOptions, stateOptions, mergeEntries } = this.get();
    return this.dialogService
      .open<Record<string, 'a' | 'b'>>({
        component: this.dialogComponent,
        styleClass: 'dialog__size-lg',
        data: { businessTypeOptions, industryOptions, stateOptions, mergeEntries },
      })
      .pipe(
        filter(Boolean),
        finalize(() => this.set({ mergeEntries: [], modalOpened: false })),
      );
  }

  loadData() {
    const merchantId = this.route.snapshot.paramMap.get('merchantId');
    if (merchantId && merchantId !== 'new') {
      this.set({ merchant: this.mca.dbaRec });
    } else {
      this.set({ merchantFormDisabled: false });
    }
    this.connect(
      'industryOptions',
      this.refService
        .listReferenceRecord<Industry[]>('industry')
        .pipe(
          map(indstries => [
            { value: 0, label: '' },
            ...indstries.map(({ id, name }: { id: number; name: string }) => ({ value: id, label: name })),
          ]),
        ),
    );
    this.connect(
      'businessTypeOptions',
      this.refService
        .getBusinessTypes()
        .pipe(map(bts => [{ label: '', value: '' }, ...bts.map((v: any) => ({ label: v.name, value: v.name }))])),
    );
  }

  private validate() {
    const validationContainer = this.get('validationContainer');
    if (validationContainer.invalid) {
      validationContainer.markAllAsTouched();
      Object.keys(validationContainer.controls).forEach(controlName => {
        const control = validationContainer.get(controlName);
        if (control?.invalid) {
          this.messageService.add({
            severity: 'error',
            summary: `Control "${controlName}" is invalid`,
            detail: `${JSON.stringify(control.errors)}`,
          });
        }
      });
      return false;
    }
    if (
      this.mca &&
      this.permissionsService.dataEntry() &&
      !dataentryMcaStatuses.includes(this.refService.getStatusLabel(this.mca.position.status ?? 0))
    ) {
      this.set({
        refCompaniesDisabled: true,
        merchantFormDisabled: true,
      });
      this.messageService.add({
        severity: 'error',
        summary: 'Error Message',
        detail: 'No permission',
      });
      return false;
    }
    return true;
  }
}
