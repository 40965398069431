import { OCPayment, OutstandingLoan } from '@mca/shared/domain';
import { MCAProgramTypes } from './mca-consts';

export class OfferMcaRec {
  mcaid = 0;
  dbaName = '';
  legalName = '';
  state = '';
  fundingType = 0;
  industry = '';
  timeInBusiness = 0;
  cashAdvances = '';
  creditScore = 0;
  revenue = 0;
  status = '';
  renewal = '';

  partPtc = 0;
  partAmt = 0;
  rtr = 0;
  totalAmt = 0;
  expectedProfit = 0;
  dailyAmt = 0;
  dailyAmtNet = 0;
  upfrontDefManagementFeeAmt = 0;
  upfrontDefManagementFee = 0;
  mgmtFeeBase = 'fa';
  byPaymnetDefManagementFeeAmt = 0;
  byPaymnetDefManagementFee = 0;
  commisionamt = 0;
  commisionpct = 0;

  payments = 0;
  consolidateDeposits: OCPayment[] = [];

  mcaFundingAmount = 0;
  mcaRate = 0;
  mcaRTR = 0;
  mcaDailyPayment = 0;
  numberOfPayments = 0;
  mcaComisions = 0;
  mcaProcessingFee = 0;
  mcaOutstandingLoans: OutstandingLoan[] = [];
}

export class ExposureRec {
  schedule: ExpectedExposureData[] = [];
  totDeposit = 0;
  totPayment = 0;
  totFee = 0;
  totComm = 0;
  totExposure = 0;
  totExposureWithCom?: number;
  totPart = 0;
}

export class ExpectedExposureData {
  commision = 0;
  deposit = 0;
  exposure = 0;
  exposureWithComm = 0;
  fee = 0;
  grossPayment = 0;
  netPayment = 0;
  totDeposit = 0;
  weekNum = 0;
  weeklyExposure = 0;
}

export interface ScheduleResponseForm {
  amount: number;
  withdrAmt: number;
  days: number;
  daysDisc: number;
  fixed_cf: number;
}

export interface ScheduleResponseParams {
  additionalAmt: number;
  netToMerchantAmt: number;
  feeAmt: number;
  buyOutAmt: number;
  savingsPct: number;
  expAsFundAmt: number;

  expectedRet: number;
  expectedRetDisc: number;

  trueRate: number;
  trueRateDisc: number;

  trueRateWithComm: number;
  trueRateWithCommDisc: number;

  dParam: number;
  dParamDisc: number;

  exposure: ExpectedExposureData[];

  deposits: OCPayment[];
  depositsGross: number;
  withdrawals: OCPayment[];
}

export interface ScheduleResponse extends ScheduleResponseForm, ScheduleResponseParams {}

export const fillExposureInputRecord = (inp: OfferMcaRec, rate: number) => {
  const partAmt: number = inp.mcaFundingAmount * rate;
  inp.partAmt = partAmt;
  inp.rtr = inp.mcaRTR * rate;
  inp.dailyAmt = inp.mcaDailyPayment * rate;
  const dailyAmt: number = inp.mcaDailyPayment * rate;
  const byPayFee: number = inp.byPaymnetDefManagementFee;
  inp.dailyAmtNet = dailyAmt - (dailyAmt * byPayFee) / 100;

  inp.upfrontDefManagementFeeAmt =
    inp.mgmtFeeBase === 'fa' ? (partAmt * inp.upfrontDefManagementFee) / 100 : (inp.rtr * inp.upfrontDefManagementFee) / 100;

  inp.byPaymnetDefManagementFeeAmt = (inp.rtr * inp.byPaymnetDefManagementFee) / 100;
  inp.commisionamt = (partAmt * inp.commisionpct) / 100;
  inp.totalAmt = partAmt + inp.commisionamt + inp.upfrontDefManagementFeeAmt;
  inp.expectedProfit = inp.rtr - inp.totalAmt;
};

export const calcExposureTotals = (expRec: ExposureRec): void => {
  expRec.totDeposit = 0;
  expRec.totFee = 0;
  expRec.totPart = 0;
  expRec.totComm = 0;
  expRec.totPayment = 0;
  expRec.totExposure = expRec.schedule.length ? Math.max(...expRec.schedule.map(i => i.exposure)) : 0;
  expRec.totExposureWithCom = expRec.schedule.length ? Math.max(...expRec.schedule.map(i => i.exposureWithComm ?? 0)) : undefined;

  expRec.schedule.forEach(node => {
    expRec.totPart += node.totDeposit;
    expRec.totComm += node.commision;
    expRec.totFee += node.fee;
    expRec.totPayment += node.netPayment;
    expRec.totDeposit += node.deposit;
    // expRec.totExposureWithCom = expRec.totExposure + expRec.totComm;
  });
};

export const calcLenderExposure = (offer: OfferMcaRec, expRec: ExposureRec): ExposureRec => {
  let lenderExpRec: ExposureRec;
  if (offer.fundingType === MCAProgramTypes.consolidation) {
    lenderExpRec = calcLenderExposureConsolidatedACH(offer, expRec);
  } else {
    lenderExpRec = calcLenderExposureFixedACH(offer, expRec);
  }
  calcExposureTotals(lenderExpRec);
  return lenderExpRec;
};

const calcLenderExposureFixedACH = (offer: OfferMcaRec, expRec: ExposureRec): ExposureRec => new ExposureRec();

const calcLenderExposureConsolidatedACH = (offer: OfferMcaRec, expRec: ExposureRec) => {
  const lenderExpRec = new ExposureRec();

  const rate = offer.partPtc / 100;
  const commissionRate = offer.commisionpct / 100;
  const dailyFeeRate = offer.byPaymnetDefManagementFee / 100;
  let tempFee = offer.upfrontDefManagementFeeAmt;

  expRec.schedule.forEach(node => {
    const deposit = node.deposit * rate;
    const commission = deposit * commissionRate;
    const netPayment = node.netPayment * rate;
    const fee = netPayment * dailyFeeRate;
    lenderExpRec.schedule.push({
      deposit: deposit + tempFee,
      totDeposit: deposit + tempFee + commission,
      netPayment: netPayment - fee,
      grossPayment: node.grossPayment * rate,
      exposure: node.exposure * rate + fee,
      weeklyExposure: node.weeklyExposure * rate + fee,
      exposureWithComm: node.exposureWithComm,
      weekNum: node.weekNum,
      commision: commission,
      fee,
    });
    tempFee = 0;
  });
  return lenderExpRec;
};
