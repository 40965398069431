import { Injectable, computed, inject, signal } from '@angular/core';
import { AuthService, PermissionsService } from '@mca/auth/api';
import { ReferencesService } from '@mca/references/api';
import { getPersonName, UserLoginInfo, UserReferenceService, UserService } from '@mca/user/api';
import { combineLatest, filter, first, map } from 'rxjs';
import { AssignmentTypes, programOptions } from '../entities/mca-consts';
import { enumToOptions } from '@mca/shared/util';
import { McaRateTypes } from '@mca/shared/domain';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { SelectItem } from 'primeng/api';
import { McaService } from '../infrastructure/mca.service';

export interface McaStatusStat {
  count: number;
  status: string;
}

@Injectable({
  providedIn: 'root',
})
export class McaListService {
  private refService = inject(ReferencesService);
  private userRefService = inject(UserReferenceService);
  private permissionsService = inject(PermissionsService);
  private authService = inject(AuthService);
  private userService = inject(UserService);
  private mcaService = inject(McaService);

  currentUser?: UserLoginInfo | null;
  defaultUserItems = [
    { label: 'Any User', value: null },
    { label: 'Assigned to me', value: this.currentUser?.id ?? 0 },
  ];

  fundingTypeOptions = [{ label: 'All Funding Types', value: '' }, ...programOptions];
  rateTypeOptions = [{ value: 0, label: 'Any' }, ...enumToOptions(McaRateTypes)];
  assigneeOptions = [{ label: 'Any Type', value: 0 }, ...enumToOptions(AssignmentTypes)];

  statusOptions = toSignal(
    this.refService
      .getStatuses()
      .pipe(map(statuses => [{ label: 'Any Status', value: '' }, ...(statuses.map(s => ({ label: s.status, value: s.id })) ?? [])])),
    { initialValue: [] },
  );

  userRoleFilter = signal<number[] | null | undefined>(undefined);
  userOptions = computed(() => [
    ...this.defaultUserItems,
    ...this.userService
      .userNames()
      .filter(user => !this.userRoleFilter()?.length || user.roles?.some(roleId => this.userRoleFilter()?.includes(roleId)))
      .map(user => ({ value: user.id, label: user.name })),
  ]);
  roleOptions = toSignal(
    this.userRefService.roles$.pipe(
      map(roles => [
        { label: 'Assigned to my role', value: this.currentUser?.roles ?? 0 },
        ...roles.map(role => ({
          label: role.name,
          value: role.id,
        })),
      ]),
    ),
    { initialValue: [] },
  );

  isoRelationOptions = signal<SelectItem[]>([]);
  isoOptions = signal<SelectItem[]>([]);

  optionsLoaded$ = combineLatest([toObservable(this.isoOptions), toObservable(this.isoRelationOptions)]).pipe(
    filter(
      ([isoOptions, isoRelationOptions]) =>
        (!!isoOptions.length && !!isoRelationOptions.length) || !this.permissionsService.hasPermission('mca_filters_iso'),
    ),
    first(),
  );

  stats = signal(
    {} as {
      statusOther: McaStatusStat[];
      statusACHNACHA: McaStatusStat[];
      statusPerforming: McaStatusStat[];
      statusUnderwriting: McaStatusStat[];
      statusBilling: McaStatusStat[];
      statusDecline: McaStatusStat[];
    },
  );

  constructor() {
    this.currentUser = this.authService.currentUser();
    this.loadUsers();
  }

  updateStats() {
    this.mcaService.getStatusStat().subscribe(res =>
      this.stats.set({
        statusOther: res['Other'],
        statusACHNACHA: res['Calculation Department'],
        statusPerforming: res['Accounting Department'],
        statusUnderwriting: res['Final Underwriting Department'],
        statusBilling: res['Data Processing Department'],
        statusDecline: res['Pricing Department'],
      }),
    );
  }

  private loadUsers() {
    if (!this.permissionsService.hasPermission('mca_filters_iso')) {
      return;
    }
    this.userRefService.users$.pipe(first()).subscribe((users: UserLoginInfo[]) => {
      const usersWithRoles = users.filter(u => u.roles);
      const userToOption = (u: any) => ({ label: getPersonName(u), value: u.id });
      const isoRelationOptions = usersWithRoles.filter(u => this.permissionsService.hasRole('role_iso_relations', u)).map(userToOption);
      const isoOptions = usersWithRoles.filter(u => this.permissionsService.isIso(u)).map(userToOption);
      this.isoRelationOptions.set([{ label: 'Any', value: 0 }, ...isoRelationOptions]);
      this.isoOptions.set([{ label: 'Any', value: 0 }, ...isoOptions]);
    });
  }
}
