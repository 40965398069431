import { Injectable, OnDestroy, inject } from '@angular/core';
import { AuthService, PermissionNames, PermissionsFilterService, PermissionsService } from '@mca/auth/api';
import { ReferencesService } from '@mca/references/api';
import { OfferStatus } from '@mca/shared/domain';
import { RxState } from '@rx-angular/state';
import { first, map, of, switchMap, zip } from 'rxjs';
import { MCAStatuses } from '../../entities/mca-consts';
import { tabsPermissions } from '../../infrastructure/tabs-permissions';
import { IsoCommissionsService } from './iso-commissions.service';
import { McaOffersFeatureService } from './mca-offers-feature.service';
import { McaPageService } from '../mca-page.service';
import { McaOffer } from '../../entities/offer';

const ISO_USER_ACCESS_PERMISSION_FILTERS = 'iso-user-access';

const allTabsPermissions = Object.values(tabsPermissions).flat();
const restrictedTabs: (keyof typeof tabsPermissions)[] = [
  'docs',
  'finance',
  'underwriting',
  'transactions',
  'notes',
  'workflow',
  'events',
  'performance',
  'datamerch',
];
const restrictedTabsPermissions = Object.entries(tabsPermissions)
  .filter(([key]) => restrictedTabs.includes(key as keyof typeof tabsPermissions))
  .map(([key, value]) => value)
  .flat();
const statusPagePermissions: PermissionNames[] = ['mca_side_notes_r', 'mca_status_renewals'];

interface State {
  restrictedMessage: string | null;
}

@Injectable()
export class IsoUserAccessService implements OnDestroy {
  private authService = inject(AuthService);
  private refService = inject(ReferencesService);
  private isoCommissionsService = inject(IsoCommissionsService);
  private offersService = inject(McaOffersFeatureService);
  private mcaPageService = inject(McaPageService);
  private permissionsFilterService = inject(PermissionsFilterService);
  private permissionsService = inject(PermissionsService);
  private state = inject<RxState<State>>(RxState);

  restrictedMessage$ = this.state.select('restrictedMessage');

  constructor() {
    this.state.set({ restrictedMessage: null });
  }

  restrictAccess() {
    this.hideEverything();
    this.mcaPageService
      .getLoadedState()
      .pipe(
        switchMap(state => this.shouldRestrictAccess(state.sets)),
        first(),
      )
      .subscribe(shouldRestrict => {
        if (!shouldRestrict) {
          this.clearFilters();
          this.state.set({ restrictedMessage: '' });
          return;
        }
        this.applyFilter();
        this.state.set({ restrictedMessage: 'This deal was not funded by your ISOSET' });
      });
  }

  ngOnDestroy(): void {
    this.clearFilters();
  }

  private hideEverything() {
    this.permissionsFilterService.setFilter(
      ISO_USER_ACCESS_PERMISSION_FILTERS,
      [...allTabsPermissions, ...statusPagePermissions],
      () => false,
    );
  }

  private clearFilters() {
    this.permissionsFilterService.unsetFilters(ISO_USER_ACCESS_PERMISSION_FILTERS);
  }

  private getRestrictedStatusIds() {
    return [
      this.refService.getStatusId(MCAStatuses.readyForFunding),
      this.refService.getStatusId(MCAStatuses.funded),
      this.refService.getStatusId(MCAStatuses.fundedNA),
      this.refService.getStatusId(MCAStatuses.rescind),
      this.refService.getStatusId(MCAStatuses.completed),
    ];
  }

  private shouldRestrictAccess(setUsers: any[]) {
    const mca = this.isoCommissionsService.mca;
    const isIsoRelation = this.permissionsService.isIsoRelOnly();
    if (!isIsoRelation || !mca.position.status || !this.getRestrictedStatusIds().includes(mca.position.status)) {
      return of(false);
    }

    const currentUser = this.authService.currentUser();
    if (!currentUser) {
      return of(true);
    }

    const userIds: number[] = [currentUser.id, ...(Array.isArray(currentUser.relatedUserId) ? currentUser.relatedUserId : [])];

    const matchedUsers = setUsers.filter(user => userIds.includes(user.userid));
    if (!matchedUsers.length) {
      return of(true);
    }

    const userSets = this.isoCommissionsService
      .get('commUserSets')
      .filter(commSet => commSet.users.some(user => userIds.includes(user.userid)));

    const someOfferIsFunded = (offers: McaOffer[]) =>
      offers.some(offer => [OfferStatus.Funded, OfferStatus['Ready for funding']].includes(offer.status));
    const userHasFundedOffer$ = zip(
      userSets.map(userSet => this.offersService.getSetOffers(userSet.setindex).pipe(map(someOfferIsFunded))),
    ).pipe(map(isoSets => isoSets.some(setIsFunded => !!setIsFunded)));
    const isAnyOfferFunded$ = this.offersService.select('offers').pipe(map(someOfferIsFunded));
    const anyActive = matchedUsers.some(user => user.active);
    return zip(userHasFundedOffer$, isAnyOfferFunded$).pipe(
      first(),
      map(([userHasFundedOffer, isAnyOfferFunded]) => !(userHasFundedOffer || (!isAnyOfferFunded && anyActive))),
    );
  }

  private applyFilter() {
    this.clearFilters();
    this.permissionsFilterService.setFilter(
      ISO_USER_ACCESS_PERMISSION_FILTERS,
      [...restrictedTabsPermissions, ...statusPagePermissions],
      () => false,
    );
  }
}
